@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

body {
  margin: 0;
  background: #f1f1f1;
  font-family: "Poppins";
  vertical-align: middle;
}

.centered {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}

p > small::before {
  content: "\A";
  white-space: pre;
  text-align: right;
}